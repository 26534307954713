import React from "react";
import { graphql } from "gatsby";

import PartsItemImageFactory from "../features/PartsItem/PartsItemImageFactory";
import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
import formatPrice from "../features/Helpers/formatPrice";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PartsItemMain from "../components/PartsItem/PartsItemMain";
import PartsItemForm from "../components/PartsItem/PartsItemForm";
import PartsItemDescription from "../components/PartsItem/PartsItemDescription";

const breadcrumbsFactory = new BreadcrumbsFactory();

const PartsItem = ({ pageContext, data }) => {
  const { category, name, slug, code, model, price, note, description } = pageContext;
  // Находим изображение записи
  const image = PartsItemImageFactory.findImage(data.images);
  // Находим статью для отображения на странице
  const onPageArticle = data.onPageArticle.nodes.shift();
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'parts',
      title: 'Катлог запчастей',
    },
    {
      path: `parts/${slug}`,
      title: name,
    }
  ]);
  return (
     <Layout crumbs={ crumbs } home={ false }>
       <SEO title={ `${name} * ${model}` } />
       <PartsItemMain
         name={ name }
         code={ code }
         price={ formatPrice(price) }
         model={ model }
         image={ image }
         category={ category }
         note={ note }
         description={ description }
       />
       <PartsItemForm
          name={ name }
          code={ code }
          price={ formatPrice(price) }
          model={ model }
       />
       { onPageArticle ? <PartsItemDescription content={ onPageArticle.html } /> : '' }
     </Layout>
   );
};

export const query = graphql`
  query ALL_PART_IMAGES($code: String) {
    images:allFile(filter: {
        dir: {regex: "/cdn/parts/"},
        name: {eq: $code}
    }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    onPageArticle:allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: "/articles/"},
        frontmatter: {parts: {eq: $code}}
      }) {
      nodes {
        frontmatter {
          title
          slug
          sort
        }
        html
      }
    }
  }
`;

export default PartsItem;
