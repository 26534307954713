import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import IconRuble from "../../assets/icons/ruble.svg";
import IconModel from "../../assets/icons/model.svg";
import IconBarCode from "../../assets/icons/bar-code.svg";
import IconBox from "../../assets/icons/box.svg";

const StyledSection = styled.section`
  /* padding-top: 2.5rem; */
`;

const StyledContainer = styled.div`
  display: grid;
  /* grid-template-columns: 25rem minmax(30rem, max-content); */
  grid-template-columns: 3fr repeat(3, 1fr);
  grid-template-areas:
    "image subheading   subheading  subheading"
    "image    heading      heading     heading"
    "image       note         note        note"
    "image description description description"
    "image     .......     .......     ......."
    "image     .......     .......     ......."
    "image     .......     .......     ......."
    "image     .......     .......     .......";
  grid-column-gap: 3rem;
  grid-row-gap: 1.5rem;
  align-items: center;

  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "subheading   subheading"
    "image             image"
    "heading         heading"
    "note               note"
    "description description";
  } 
  
  h2 {
    grid-area: subheading;
    font-size: 1.8rem;
    line-height: normal;
    margin-top: 2rem;
    margin-bottom: 0;
    color: #4e4e4e;
  }
  h1 {
    grid-area: heading;
    font-size: 2.2rem;
    &:after {
      margin-top: .2rem;
      display: block;
      content: "";
      width: 5vw;
      height: 3px;
      border-radius: 8px;
      background-color: #FDB819;
    }
  }

  .value {
    &.model {
      a {
        text-decoration: none;
      }
    }
  }

  .note {
    grid-area: note;
    justify-self: start;
    span {
      background-color: #ffc6a5;
      padding: .8rem 1.2rem;
    }
  }

  .description {
    grid-area: description;
    color: #4e4e4e;
    margin-bottom: 2rem;
  }

  .image {
    grid-area: image;
    @media (max-width: 480px) {
      grid-column: 1 / -1;
    }
  }

  .item {
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    &__desc {
      color: #4e4e4e;
      margin-left: 1.5vw;
    }

    @media (max-width: 940px) {
      grid-column: 1 / 2;
      align-self: start;
    }
  }
`;

const PartsItemMain = ({ name, model, code, price, image, category, note, description }) => {
  // Зашгружаем картинку-заглушку из файловой системы
  const data = useStaticQuery(graphql`
    query DEFAULT_IMAGE {
      file(name: {eq: "noimage"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledSection>
      <StyledContainer className="container">
        <Img className="image" fluid={ image ? image.fluid : data.file.childImageSharp.fluid } />
        <h2>Запасные части для автомобиля { model }</h2>
        <h1>{ `${name} ${model}` }</h1>
        <div className="description">{ description }</div>
        <div className="note">{ Boolean(note) && <span>{ note }</span> }</div>
        {/* Model */}
        <span className="item">
          <IconModel className="item__icon" width="24" height="24" fill="#607A97" />
          <span className="item__desc">Модель</span>
        </span>
        <span className="value model"><Link to="/">{ model }</Link></span>
        {/* Category */}
        <span className="item">
          <IconBox className="item__icon" width="24" height="24" fill="#607A97" />
          <span className="item__desc">Категория</span>
        </span>
        <span className="value category"><Link to="/parts" state={{ category }}>{ category }</Link></span>
        {/* Price */}
        <span className="item">
          <IconRuble className="item__icon" width="24" height="24" fill="#607A97" />
          <span className="item__desc">Цена</span>
        </span>
        <span className="value price">от { price }</span>
        {/* Code */}
        <span className="item">
          <IconBarCode className="item__icon" width="24" height="24" fill="#607A97" />
          <span className="item__desc">Артикул</span>
        </span>
        <span className="value code">{ code }</span>
      </StyledContainer>
    </StyledSection>
  );
};

export default PartsItemMain;