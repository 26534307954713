import styled from 'styled-components';

const Button = styled.button`
  border-radius: 4px;
  border: 1px solid #CFD2D9;
  background: #fff;
  padding: 10px 30px;
  font-family: inherit;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

export const SimpleButton = styled.button`
  border-radius: 4px;
  border: 1px solid #CFD2D9;
  background: #fff;
  padding: 10px 30px;
  font-family: inherit;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

export default Button;