import React from "react";
import styled from "styled-components";

import { Form } from '../styles/Form';
import {Container} from '../styles/Container';
import {SimpleButton} from "../styles/Button";

import FormHandler from '../../features/Email/FormHandler';

const formHandler = new FormHandler();

const StyledSection = styled.section`
  padding: 3rem 0 5rem 0;
  margin-top: 3rem;
  background: #DCDCDC;  /* fallback for old browsers */
`;

const StyledContainer = styled(Container)`
  h3 {
    text-align: center;
    color: #2F4F4F;
    margin-bottom: 2rem;
  }
  .desc {
    margin-top: 1rem;
    text-align: center;
    color: #2F4F4F;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 940px) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

const PartsItemForm = ({model, code, name, price}) => {

  const [stateData, setStateData] = React.useState({
    subject: "Заявка",
    username: "",
    contact: "",
    model,
    message: `Заявка на ${name}, артикул ${code}.`,
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const { success } = await formHandler.postFormData(stateData);
    if (success) {
      // Показываем уведомление об успешной отправке
      // displaySnackbar();
      // Очищаем данные формы
      setStateData({
        ...stateData,
        username: "",
        contact: "",
      });
    }
  };

  return (
    <StyledSection>
      <StyledContainer>
        <h3>Оставить заявку</h3>
        <StyledForm autoComplete="off" onSubmit={ onFormSubmit }>
          <input
            id="username"
            name="username"
            type="text"
            placeholder="Имя"
            required
            value={stateData.username}
            onChange={(e) => setStateData({
              ...stateData,
              username: e.target.value,
            })}
          />
          <input
            id="contact"
            name="contact"
            type="text"
            placeholder="Телефон или email"
            required
            value={stateData.contact}
            onChange={(e) => {
              setStateData({
                ...stateData,
                contact: e.target.value,
              });
            }}
          />
          <SimpleButton type="submit">Отправить</SimpleButton>
        </StyledForm>
        <div className="desc">Оставьте заявку на подбор деталей и наши специалисты отправлят вам перечень возможных вариантов</div>
      </StyledContainer>
    </StyledSection>
  );
};

export default PartsItemForm;