import React from 'react';
import styled from 'styled-components';

import {Container} from '../styles/Container';

const DescriptionContainer = styled(Container)`
  padding: 3rem 0;
`;

const StyledArticle = styled.article`
  h2,
  h3 {
    text-align: center;
  }
`;

const PartsItemDescription = ({content}) => (
  <DescriptionContainer>
    <StyledArticle dangerouslySetInnerHTML={{__html: content}} />
  </DescriptionContainer>
);

export default PartsItemDescription;